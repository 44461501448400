import { createContext, useContext, useState, useEffect } from "react";
import { formatISO } from "date-fns";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { USER_COLLECTION } from "../../global/utils";
import { User } from "../../firebase/types";
import algoliasearch from "algoliasearch/lite";
import { useAuthService } from "./AuthContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firestore, storage } from "../../firebase/init";
import { usePoopService } from "./PoopContext";
import { baseUrl } from "../../global/utils";

const searchClient = algoliasearch(
  "FNTW24ZQQY",
  "8c5171ae6a50d6f4b288917794bc1997"
);
const index = searchClient.initIndex("firestore");

interface UserServiceContextType {
  foundUsers: User[];
  mostFollowedUsers: User[];
  setFoundUsers: (users: User[]) => void;
  searchUsersByUsername: (username: string) => Promise<void>;
  saveMostCommonTypeAndDescription: (
    type: string,
    description: string
  ) => Promise<void>;
  saveAlwaysView: (value: boolean) => Promise<void>;
  saveEnableNotifications: (value: boolean) => Promise<void>;
  updatePremium: (
    value: boolean,
    data: any,
    subscriptionStatus: "active" | "canceled"
  ) => Promise<void>;
  saveDarkMode: (value: boolean) => Promise<void>;
  uploadUserImage: (image: any) => Promise<void>;
  follow: (uid: string) => Promise<void>;
  removeFollow: (uid: string) => Promise<void>;
  getUserAccount: (uid: string) => Promise<User>;
  getFollowers: (uid: string) => Promise<User[]>;
  getFollowings: (uid: string) => Promise<User[]>;
  getAndSetMostFollowedUsers: () => Promise<void>;
  editUsername: (username: string) => Promise<void>;
  getTotalNumOfUsers: () => Promise<{
    totalUsers: number;
    usersWithEmail: number;
  }>;
  getTotalNumberOfPremiumUsers: () => Promise<number>;
  updatePoints: (points: number) => Promise<void>;
  updateStreaks: () => Promise<void>;
  getAndSetLongestStreakUser: () => Promise<User[]>;
  getAndSetCurrentStreakUser: () => Promise<User[]>;
  getAllUsersByDates: (startDate: string, endDate: string) => Promise<User[]>;
}

const UserServiceContext = createContext<UserServiceContextType>({
  foundUsers: [],
  mostFollowedUsers: [],
  setFoundUsers: () => {},
  searchUsersByUsername: async () => {},
  saveMostCommonTypeAndDescription: async () => {},
  saveAlwaysView: async () => {},
  saveEnableNotifications: async () => {},
  updatePremium: async () => {},
  saveDarkMode: async () => {},
  uploadUserImage: async () => {},
  follow: async () => {},
  removeFollow: async () => {},
  getUserAccount: async () => {
    return {} as User;
  },
  getFollowers: async () => {
    return [] as User[];
  },
  getFollowings: async () => {
    return [] as User[];
  },
  getAndSetMostFollowedUsers: async () => {},
  editUsername: async () => {},
  getTotalNumOfUsers: async () => {
    return { totalUsers: 0, usersWithEmail: 0 };
  },
  getTotalNumberOfPremiumUsers: async () => {
    return 0;
  },
  updatePoints: async () => {},
  updateStreaks: async () => {},
  getAndSetLongestStreakUser: async () => {
    return [] as User[];
  },
  getAndSetCurrentStreakUser: async () => {
    return [] as User[];
  },
  getAllUsersByDates: async () => {
    return [] as User[];
  },
});
//user?.premium && user.subscriptionStatus === "active"
export const useUserService = () => useContext(UserServiceContext);

export const UserServiceProvider = ({ children }: any) => {
  const [foundUsers, setFoundUsers] = useState<User[]>([]);
  const [mostFollowedUsers, setMostFollowedUsers] = useState<User[]>([]);
  const [checkedOnPremium, setCheckedOnPremium] = useState(false);

  const { updateAllPostedPoops, updateAllPostedPoopsForPremium } =
    usePoopService();
  const { user, setUser } = useAuthService();

  useEffect(() => {
    if (user && !checkedOnPremium) {
      checkIfUserHasPremium();
      setCheckedOnPremium(true);
    }
    //eslint-disable-next-line
  }, [user]);

  const checkIfUserHasPremium = async () => {
    if (!user) return;
    if (user.premium && user.subscription?.id) {
      // Check if the subscription is still active
      // If not, update the user's premium status to false
      const res = await fetch(`${baseUrl}/validateStripeReceipt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          receiptId: user?.subscription?.id,
        }),
      });
      const { isExpired, subscription } = await res.json();
      if (isExpired) {
        updatePremium(false, subscription, "canceled");
      }
      let updatedUser = { ...user };
      updatedUser.premium = !isExpired;
      updatedUser.subscriptionStatus = isExpired ? "canceled" : "active";
      setUser(updatedUser as User);
    }
  };

  const getAndSetLongestStreakUser = async () => {
    let initialQuery = query(
      USER_COLLECTION,
      orderBy("longestStreak", "desc"),
      limit(50)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: User[] = documentSnapshots.docs.map(
      (document) => document.data() as User
    );
    // lastVisible.current = documentData[documentData.length - 1];
    return documentData;
  };

  const getAndSetCurrentStreakUser = async () => {
    let initialQuery = query(
      USER_COLLECTION,
      orderBy("currentStreak", "desc"),
      limit(50)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: User[] = documentSnapshots.docs.map(
      (document) => document.data() as User
    );
    // lastVisible.current = documentData[documentData.length - 1];
    return documentData;
  };

  const getAndSetMostFollowedUsers = async () => {
    let initialQuery = query(
      USER_COLLECTION,
      orderBy("followers", "desc"),
      limit(50)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: User[] = documentSnapshots.docs.map(
      (document) => document.data() as User
    );
    // lastVisible.current = documentData[documentData.length - 1];
    setMostFollowedUsers(documentData);
  };

  const searchUsersByUsername = async (username: string) => {
    const { hits } = await index.search(username);
    setFoundUsers(hits as unknown as User[]);
  };

  const saveMostCommonTypeAndDescription = async (
    type: string,
    description: string
  ) => {
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, {
      poopTypeDescription: {
        type,
        description,
        modifiedDate: formatISO(new Date()),
      },
    });
  };

  const saveAlwaysView = async (value: boolean) => {
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, { alwaysView: value });
  };

  const saveEnableNotifications = async (value: boolean) => {
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, { enableNotifications: value });
  };

  const saveDarkMode = async (value: boolean) => {
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, { darkMode: value });
  };

  const uploadUserImage = async (image: any) => {
    if (!user) return;
    const filePath = `users/${user?.email}/avatar`;
    const refStorage = ref(storage, filePath);
    let uploadedImage = await uploadBytes(refStorage, image);
    const downloadUrl = await getDownloadURL(uploadedImage.ref);
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, { photoURL: downloadUrl });
    updateAllPostedPoops(downloadUrl, user.username);
  };

  const removeFollow = async (uid: string) => {
    await removeFollower(uid);
    await removeFollowing(uid);
  };

  const removeFollower = async (uid: string) => {
    if (!user || !user.uid) {
      console.error("Current user UID is undefined");
      return;
    }
    const userRef = doc(USER_COLLECTION, uid);
    const followerRef = doc(collection(userRef, "followers"), user.uid);
    await deleteDoc(followerRef);
    await updateDoc(userRef, { followers: increment(-1) });
  };

  const removeFollowing = async (uid: string) => {
    if (!user || !user.uid) {
      console.error("Current user UID is undefined");
      return;
    }
    const userRef = doc(USER_COLLECTION, user.uid);
    const followingRef = doc(collection(userRef, "followings"), uid);
    await deleteDoc(followingRef);
    await updateDoc(userRef, { followings: increment(-1) });
    let updatedUser = { ...user };
    updatedUser.followings = updatedUser.followings - 1;
    setUser(updatedUser as User);
  };

  const follow = async (uid: string) => {
    await addFollower(uid);
    await addFollowing(uid);
  };

  const addFollowing = async (uid: string) => {
    if (!user || !user.uid) {
      console.error("Current user UID is undefined");
      return;
    }
    const userRef = doc(USER_COLLECTION, user?.uid);
    const followingRef = doc(collection(userRef, "followings"), uid);

    // Create a reference to the followed user's document
    const followedUserRef = doc(USER_COLLECTION, uid);

    await setDoc(followingRef, { userRef: followedUserRef });
    await updateDoc(userRef, { followings: increment(1) });
    let updatedUser = { ...user };
    updatedUser.followings = updatedUser.followings + 1;
    setUser(updatedUser as User);
  };

  const addFollower = async (uid: string) => {
    if (!user || !user.uid) {
      console.error("Current user UID is undefined");
      return;
    }
    const userRef = doc(USER_COLLECTION, uid);
    const followerRef = doc(collection(userRef, "followers"), user?.uid);

    // Create a reference to the current user's document
    const currentUserRef = doc(USER_COLLECTION, user.uid);

    await setDoc(followerRef, { userRef: currentUserRef });
    await updateDoc(userRef, { followers: increment(1) });
  };

  const getUserAccount = async (uid: string) => {
    const userRef = doc(USER_COLLECTION, uid);
    const user = await getDoc(userRef);
    return user.data() as User;
  };

  const getFollowers = async (uid: string) => {
    // Reference to the user's followers subcollection
    const followersCollectionRef = collection(
      firestore,
      `users/${uid}/followers`
    );

    // Fetch the documents in the followers subcollection
    const followersSnapshot = await getDocs(followersCollectionRef);

    // Map over each follower doc to fetch the userRef (their user document)
    const followers = await Promise.all(
      followersSnapshot.docs.map(async (doc) => {
        const userRef = doc.data().userRef;

        // Assuming userRef is a DocumentReference, fetch the referenced user document
        const userDocSnapshot = await getDoc(userRef);

        // Return the user document data if it exists, else return null
        return userDocSnapshot.exists() ? userDocSnapshot.data() : null;
      })
    );

    // Filter out any potential null values if user documents didn't exist or were not fetched correctly
    return followers.filter((follower) => follower !== null) as User[];
  };

  const getFollowings = async (uid: string) => {
    // Reference to the user's followers subcollection
    const followingsCollectionRef = collection(
      firestore,
      `users/${uid}/followings`
    );

    // Fetch the documents in the followers subcollection
    const followingsSnapshot = await getDocs(followingsCollectionRef);

    // Map over each follower doc to fetch the userRef (their user document)
    const followings = await Promise.all(
      followingsSnapshot.docs.map(async (doc) => {
        const userRef = doc.data().userRef;

        // Assuming userRef is a DocumentReference, fetch the referenced user document
        const userDocSnapshot = await getDoc(userRef);

        // Return the user document data if it exists, else return null
        return userDocSnapshot.exists() ? userDocSnapshot.data() : null;
      })
    );

    // Filter out any potential null values if user documents didn't exist or were not fetched correctly
    return followings.filter((following) => following !== null) as User[];
  };

  const updatePremium = async (
    value: boolean,
    subscription: any,
    subscriptionStatus: "canceled" | "active"
  ) => {
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, {
      premium: value,
      subscription,
      subscriptionStatus,
    });
    updateAllPostedPoopsForPremium(
      subscriptionStatus === "active" ? true : false
    );
    let updatedUser = { ...user };
    updatedUser.premium = subscriptionStatus === "active" ? true : false;
    updatedUser.subscriptionStatus = subscriptionStatus;
    setUser(updatedUser as User);
  };

  const editUsername = async (username: string) => {
    if (!user) return;
    const userRef = doc(USER_COLLECTION, user?.uid);
    updateAllPostedPoops(user.photoURL, username);
    await updateDoc(userRef, {
      username: username,
      username_searchable: username.toLowerCase().trim(),
    });
    const updatedUser = { ...user };
    updatedUser.username = username;
    setUser(updatedUser as User);
  };

  const getTotalNumberOfPremiumUsers = async () => {
    let initialQuery = query(
      USER_COLLECTION,
      where("premium", "==", true),
      where("subscriptionStatus", "==", "active")
    );
    console.log("Made it here");

    let documentSnapshots = await getDocs(initialQuery);

    // lastVisible.current = documentData[documentData.length - 1];
    console.log(documentSnapshots.size);
    return documentSnapshots.size;
  };

  const getTotalNumOfUsers = async () => {
    return { totalUsers: 0, usersWithEmail: 0 };
  };

  const updatePoints = async (points: number) => {
    if (!user) return;
    const updatedUser = { ...user };
    updatedUser.points.currentPoints = user.points.currentPoints + points;
    setUser(updatedUser as User);
    const userRef = doc(USER_COLLECTION, user?.uid);
    await updateDoc(userRef, {
      "points.currentPoints": increment(points),
    });
  };

  const updateStreaks = async () => {
    if (!user) return;

    let { lastPoopPosted, currentStreak, longestStreak } = user;
    const now = Timestamp.now(); // Firebase's server timestamp

    let tempLastPostedDate: Date;

    // Check if lastPoopPosted is a Firebase Timestamp or a JavaScript Date
    if (lastPoopPosted instanceof Timestamp) {
      tempLastPostedDate = lastPoopPosted.toDate(); // Convert Timestamp to Date
    } else if (lastPoopPosted instanceof Date) {
      tempLastPostedDate = lastPoopPosted; // Use it directly if it's a Date
    } else {
      tempLastPostedDate = now.toDate(); // Default to now if undefined or invalid
    }

    const timeDiff =
      (now.toMillis() - tempLastPostedDate.getTime()) / (1000 * 60 * 60); // Time difference in hours

    // Default streak values if undefined
    if (!currentStreak) currentStreak = 0;
    if (!longestStreak) longestStreak = 0;

    let newCurrentStreak = currentStreak;
    let newLongestStreak = longestStreak;

    // Increment or reset the current streak based on time difference
    if (timeDiff <= 28) {
      newCurrentStreak += 1;
    } else {
      newCurrentStreak = 1; // Reset the streak if more than 28 hours passed
    }

    // Update the longest streak if the current streak exceeds it
    if (newCurrentStreak >= longestStreak) {
      newLongestStreak = newCurrentStreak;
    }

    // Prepare user object and Firebase reference for update
    const userRef = doc(USER_COLLECTION, user?.uid);
    const updatedUser = {
      ...user,
      currentStreak: newCurrentStreak,
      longestStreak: newLongestStreak,
      lastPoopPosted: now, // Use Firebase Timestamp for storing
    };

    // Update the state and Firestore
    setUser(updatedUser as User);
    await updateDoc(userRef, {
      currentStreak: newCurrentStreak,
      longestStreak: newLongestStreak,
      lastPoopPosted: now,
    });
  };

  const formatToLocalISO = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Combine into an ISO-like format with the local timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const getAllUsersByDates = async (startDate: string, endDate: string) => {
    try {
      // Convert start and end dates to the desired format (e.g., "1/26/2024 00:00:00 AM" to "1/26/2024 11:59:59 PM")
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0); // Set to midnight
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59

      const formatDateTime = (date: any) =>
        date.toLocaleString("en-US", {
          hour12: true,
        });

      const startISO = formatToLocalISO(startOfDay);
      const endISO = formatToLocalISO(endOfDay);

      console.log("Formatted start date:", startISO);
      console.log("Formatted end date:", endISO);
      // Query Firestore

      const q = query(
        USER_COLLECTION,
        where("createdAt", ">=", startISO),
        where("createdAt", "<=", endISO),
        where("email", ">", "")
      );

      const querySnapshot = await getDocs(q);

      // Map results
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Users within date range:", users);
      return users as unknown as User[];
    } catch (error) {
      console.error("Error fetching users by dates:", error);
      throw error;
    }
  };

  const value = {
    foundUsers,
    mostFollowedUsers,
    setFoundUsers,
    searchUsersByUsername,
    saveMostCommonTypeAndDescription,
    saveAlwaysView,
    saveEnableNotifications,
    uploadUserImage,
    follow,
    removeFollow,
    getUserAccount,
    saveDarkMode,
    getFollowers,
    getFollowings,
    updatePremium,
    getAndSetMostFollowedUsers,
    editUsername,
    getTotalNumOfUsers,
    updatePoints,
    getTotalNumberOfPremiumUsers,
    updateStreaks,
    getAndSetCurrentStreakUser,
    getAndSetLongestStreakUser,
    getAllUsersByDates,
  };

  return (
    <UserServiceContext.Provider value={value}>
      {children}
    </UserServiceContext.Provider>
  );
};
